import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Spinner from '../../../components/spinner/Spinner';
import './../businessProfileConnect/BusinessProfileConnect.css';
import { Fragment } from 'react';
import './GridReportTool.css';
import GridMapV5 from '../../../components/gridReport/v5/gridMap/GridMapV5';
import { toast } from 'react-toastify';
import { GenerateSEOReportDataGrid, GetReportSettings } from '../../../Services';
import { useStateValue } from '../../../StateProvider';
import { GenerateReportData, GetReportDataByPlaceId } from '../../../ReportAPI';
import moment from 'moment';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';

const GridReportToolNewDemo = () => {
	const [ { user, host }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isLoadingMap, setIsLoadingMap ] = useState(false);
	const [ isGenerating, setIsGenerating ] = useState(false);
	const [ isGeneratingGrid, setIsGeneratingGrid ] = useState(false);
	const [ gridData, setGridData ] = useState();
	const [ reportData, setReportData ] = useState({
		gridData        : {},
		businessData    : {},
		competitor1Data : {},
		competitor2Data : {},
		competitor3Data : {}
	});
	const categories = [ 'business', 'competitor1', 'competitor2', 'competitor3' ];
	const settings = [
		{ type: 'profile', label: 'Categories', propValue: 'categories', responseType: 'bool' },
		{ type: 'profile', label: 'Address', propValue: 'Address', responseType: 'bool' },
		{ type: 'profile', label: 'Phone Number', propValue: 'Phone', responseType: 'bool' },
		{ type: 'profile', label: 'Website URL', propValue: 'Website', responseType: 'bool' },
		{ type: 'profile', label: 'Contact URL', propValue: 'ContactUrl', responseType: 'bool' },
		{ type: 'profile', label: 'Description', propValue: 'Description', responseType: 'bool' },
		{ type: 'profile', label: 'Business Hours', propValue: 'WorkTime', propSubValue: 'Data', responseType: 'bool' },
		{
			type         : 'reviews',
			label        : 'Total Reviews',
			propValue    : 'total',
			//printPropValue : 'total',
			responseType : 'int'
		},
		{ type: 'profile', label: 'Total Posts', propValue: null, responseType: 'int' },
		{ type: 'profile', label: 'Total Q&A', propValue: null, responseType: 'int' },
		{ type: 'profile', label: 'Total Images', propValue: 'TotalPhotos', responseType: 'int' },
		{ type: 'profile', label: 'Logo', propValue: 'Logo', responseType: 'bool' },
		{ type: 'profile', label: 'Main Image', propValue: 'MainImage', responseType: 'bool' },
		{ type: 'profile', label: 'Social Profiles', propValue: 'socialProfiles', responseType: 'bool' }
	];
	const kwSettings = [
		{ type: 'keywordsIn', label: 'Profile Categories', propValue: 'occurrencesInCategories', responseType: 'int' },
		{ type: 'keywordsIn', label: 'Profile Title', propValue: 'occurrencesInTitle', responseType: 'int' },
		{
			type         : 'keywordsIn',
			label        : 'Profile Description',
			propValue    : 'occurrencesInDescription',
			responseType : 'int'
		},
		{
			type         : 'keywordsIn',
			label        : 'Profile Reviews (Last 20)',
			propValue    : 'occurrencesInReviewsLast20',
			responseType : 'int'
		},
		{ type: 'keywordsIn', label: 'Profile Posts', propValue: 'occurrencesInPosts', responseType: 'int' },
		{ type: 'keywordsIn', label: 'Profile Q&A', propValue: 'occurrencesInQA', responseType: 'int' },
		{ type: 'keywordsIn', label: 'Website Landing Page', propValue: 'occurrencesInHtml', responseType: 'int' },
		{ type: 'keywordsIn', label: 'Website Pages Indexed', propValue: '', responseType: 'int' }
	];
	const reviewsLast20Settings = [
		{ label: 'Average Rating', propValue: 'averageRating', responseType: 'int' },
		{ label: 'Average Words Per Review', propValue: 'averageWordsCount', responseType: 'int' },
		{ label: 'Have Replies', propValue: 'replies', responseType: 'int' },
		{ label: 'Local Guide Reviews', propValue: 'localGuide', responseType: 'int' },
		{ label: 'Negative Reviews', propValue: 'negative', responseType: 'int' },
		{ label: 'Negative Reviews Have Replies', propValue: 'negativeWithReplies', responseType: 'int' }
	];
	const reviewsLast30DaysSettings = [
		{ label: 'Total Reviews', propValue: 'results', responseType: 'int' },
		{ label: 'Average Rating', propValue: 'averageRating', responseType: 'int' },
		{ label: 'Average Words Per Review', propValue: 'averageWordsCount', responseType: 'int' },
		{ label: 'Have Replies', propValue: 'replies', responseType: 'int' },
		{ label: 'Local Guide Reviews', propValue: 'localGuide', responseType: 'int' },
		{ label: 'Negative Reviews', propValue: 'negative', responseType: 'int' },
		{ label: 'Negative Reviews Have Replies', propValue: 'negativeWithReplies', responseType: 'int' }
	];
	const [ reportSettings, setReportSettings ] = useState();
	const [ businessPlaceId, setBusinessPlaceId ] = useState();
	const [ keyword, setKeyword ] = useState();
	const [ competitor1PlaceId, setCompetitor1PlaceId ] = useState();
	const [ competitor2PlaceId, setCompetitor2PlaceId ] = useState();
	const [ competitor3PlaceId, setCompetitor3PlaceId ] = useState();
	const [ showJsonOutputDialog, setShowJsonOutputDialog ] = useState(false);
	const [ jsonOutput, setJsonOutput ] = useState();
	const { placeId, name } = useParams();
	const gridMapContainerRef = useRef();

	useEffect(() => {
		getReportSettings();
	}, []);

	useEffect(() => {
		if (placeId && name) {
			getReportData();
		}
	}, []);

	useEffect(
		() => {
			if (window.mapReady) {
				setIsLoadingMap(false);
			}
		},
		[ window.mapReady ]
	);

	const getReportSettings = async () => {
		let response = await GetReportSettings();

		if (response && response.data) {
			setReportSettings(response.data);
		}
	};

	const getReportData = async () => {
		let response = await GetReportDataByPlaceId(placeId, name);

		if (response && response.data) {
			let tempReportData = response.data;

			setReportData(tempReportData);

			if (tempReportData.inputData) {
				setBusinessPlaceId(tempReportData.inputData.businessPlaceId);
				setKeyword(tempReportData.inputData.keywords);

				if (
					tempReportData.inputData.competitorsPlaceIds &&
					tempReportData.inputData.competitorsPlaceIds.length > 0
				) {
					setCompetitor1PlaceId(tempReportData.inputData.competitorsPlaceIds[0]);
					setCompetitor2PlaceId(tempReportData.inputData.competitorsPlaceIds[1] || null);
					setCompetitor3PlaceId(tempReportData.inputData.competitorsPlaceIds[2] || null);
				}
			}
		}
	};

	const generateReport = async (e) => {
		e.preventDefault();

		if (!businessPlaceId || businessPlaceId.trim() === '') {
			toast.error('Business Place Id is required');
			return;
		}

		// if (!keyword || keyword.trim() === '') {
		// 	toast.error('Keyword is required');
		// 	return;
		// }

		// if (!competitor1PlaceId || competitor1PlaceId.trim() === '') {
		// 	toast.error('Competitor 1 Place Id is required');
		// 	return;
		// }

		// if (!competitor2PlaceId || competitor2PlaceId.trim() === '') {
		// 	toast.error('Competitor 2 Place Id is required');
		// 	return;
		// }

		// if (!competitor3PlaceId || competitor3PlaceId.trim() === '') {
		// 	toast.error('Competitor 3 Place Id is required');
		// 	return;
		// }

		setIsGenerating(true);
		setIsLoading(true);

		//create full report
		let data = {
			businessPlaceId    : businessPlaceId,
			keywords           : keyword,
			competitor1PlaceId : competitor1PlaceId,
			competitor2PlaceId : competitor2PlaceId,
			competitor3PlaceId : competitor3PlaceId
		};

		const reportResponse = await GenerateReportData(data);

		if (reportResponse.success) {
			let tempReport = reportResponse.data;

			if (tempReport.businessData) {
				// let gridPoints = 7;
				// let pointDistance = 1; //parseFloat(areaRadius * 2 / (gridPoints - 1));
				// //generate grid report
				// let gridData = {
				// 	salesUserId   : user && user.isSalesUser ? user.id : null,
				// 	campaignId    : null,
				// 	keywords      : [ keyword ],
				// 	lat           : tempReport.businessData.lat,
				// 	lng           : tempReport.businessData.lng,
				// 	googlePlaceId : businessPlaceId,
				// 	gridPoints    : gridPoints,
				// 	pointDistance : pointDistance,
				// 	gridApiType   : 'dseo'
				// };
				// const gridReportResponse = await GenerateSEOReportDataGrid(gridData);
				// if (gridReportResponse.success) {
				// 	tempReport.gridData = gridReportResponse;
				// }
			}

			setReportData({
				...reportData,
				tempReport
			});

			if (tempReport.reportName) {
				window.location.href = window.location.href
					.replace(placeId, businessPlaceId)
					.replace(name, tempReport.reportName);
			}
		} else {
			toast.error(reportResponse.message);
			console.error(reportResponse);
		}

		setIsGenerating(false);
		setIsLoading(false);

		// //first get business profile info in order to create grid map
		// let businessProfileResponse = await GetBusinessProfileDetails({ placeId: businessPlaceId });

		// if (businessProfileResponse.success) {

		// }
	};

	const printJsonNodeValue = (cat, setting) => {
		let output = '';

		let nodeValue = setting.propValue;
		let subNodeValue = setting.propSubValue;
		let label = setting.label;

		if (reportData && reportData[cat + 'Data']) {
			let foundNodeValue = reportData[cat + 'Data'][setting.type];

			if (nodeValue) {
				foundNodeValue = reportData[cat + 'Data'][setting.type][nodeValue];
			}

			if (nodeValue && subNodeValue) {
				foundNodeValue = reportData[cat + 'Data'][setting.type][nodeValue][subNodeValue];
			}

			if (foundNodeValue) {
				if (label) {
					output += `<h4 class="json-output-title mb-3">${label}</h4>`;
				}

				if (Array.isArray(foundNodeValue)) {
					foundNodeValue.map((value, index) => {
						if (typeof value === 'object') {
							output += '<div class="json-output-array-item mb-3" key="' + index + '">';
							output += outputObjectAsHtml(value, index, 0);
							output += '</div>';
						} else {
							output += outputValueAsHtml(value, index);
						}
					});
				} else if (typeof foundNodeValue === 'object') {
					output += outputObjectAsHtml(foundNodeValue, null, 0);
				} else {
					output += outputValueAsHtml(foundNodeValue);
				}
			}
		}

		setJsonOutput(output);
		toggleJsonOutputDialog();

		return output;
	};

	const outputObjectAsHtml = (objectValue, arrayIndex = null, nestingLevel = 0) => {
		let output = '';

		// if (nestingLevel === 1 && typeof objectValue === 'object') {
		// 	// output += `<div class="form-label json-output-object-item-value" dangerouslySetInnerHTML="${objectValue}"></div>`;
		// 	output += `<div class="form-label json-output-object-item-value">${objectValue}</div>`;
		// 	return output;
		// }

		Object.keys(objectValue).map((key, objectIndex) => {
			let parsedItemValue;

			if (objectValue[key]) {
				if (typeof objectValue[key] === 'object') {
					//parsedItemValue += objectValue[key];

					output += outputObjectAsHtml(objectValue[key], null, nestingLevel++);

					// for (var itemValueKey in objectValue) {
					// 	if (objectValue.hasOwnProperty(itemValueKey)) {
					// 		parsedItemValue +=
					// 			objectValue[itemValueKey] + (key === 'keywordItems' ? '\n' : ', ');
					// 	}
					// }
					// if (parsedItemValue && parsedItemValue.length > 2) {
					// 	parsedItemValue = parsedItemValue.substring(0, parsedItemValue.length - 2);
					// }
				} else {
					parsedItemValue = objectValue[key];
				}

				if (parsedItemValue === true) {
					parsedItemValue = 'Yes';
				} else if (parsedItemValue === false) {
					parsedItemValue = 'No';
				}

				output += `<div class="col-12 json-output-object-item" key="${objectIndex}">`;
				output += `<label class="form-label json-output-object-item-label" title="${key}"><strong>${arrayIndex !==
					null && objectIndex === 0
					? `<span>${arrayIndex + 1}. </span>`
					: ''}<span>${key}:</span></strong></label>`;

				if (key === 'keywordItems') {
					output += `<textarea class="form-control json-output-object-item-value" rows=6 value="${parsedItemValue}" readOnly />`;
				} else {
					if (typeof parsedItemValue === 'string') {
						if (parsedItemValue.indexOf('https://') > -1 || parsedItemValue.indexOf('http://') > -1) {
							output += `<a class="btn btn-link p-0 json-output-object-item-value" title="${parsedItemValue}" target="_blank" href="${parsedItemValue}">${parsedItemValue}</a>`;
						} else {
							output += `<label class="form-label json-output-object-item-value" title="${parsedItemValue}">${parsedItemValue}</label>`;
						}
					} else {
						//output += `<label class="form-label json-output-object-item-value" title="${parsedItemValue}">${parsedItemValue}</label>`;

						if (typeof parsedItemValue === 'object') {
							output += `<div class="form-label json-output-object-item-value" title="${parsedItemValue}" dangerouslySetInnerHTML="${parsedItemValue}"></div>`;
							//output += `<label class="form-label json-output-object-item-value">${parsedItemValue}</label>`;
						} else {
							output += `<label class="form-label json-output-object-item-value" title="${parsedItemValue}">${parsedItemValue}</label>`;
						}
					}
				}

				output += `</div>`;
			}
		});

		return output;
	};

	const outputValueAsHtml = (value, arrayIndex = null) => {
		let output = '';
		let ordinal = arrayIndex !== null ? `<span>${arrayIndex + 1}. </span>` : '';

		if (typeof value === 'string') {
			if (value.indexOf('https://') > -1 || value.indexOf('http://') > -1) {
				output += `<a class="btn btn-link py-0 json-output-object-item-value" title="${value}" target="_blank" href="${value}">${ordinal}${value}</a>`;
			} else {
				output += `<label class="form-label json-output-object-item-value" title="${value}">${ordinal}${value}</label>`;
			}
		} else {
			output += `<label class="form-label json-output-object-item-value" title="${value}">${ordinal}${value}</label>`;
		}

		return output;
	};

	const toggleJsonOutputDialog = async (e) => {
		setShowJsonOutputDialog(!showJsonOutputDialog);

		if (showJsonOutputDialog) {
			setJsonOutput();
		}

		if (e) e.preventDefault();
	};

	return (
		<div className="app-container">
			<div className="grid-tool-left-container">
				<div className="row p-3">
					<form className="mt-3">
						<div className="col-12 mb-3">
							<label className="form-label">Business Place Id:</label>
							<input
								type="text"
								name="businessPlaceId"
								className="form-control"
								value={businessPlaceId || ''}
								onChange={(e) => setBusinessPlaceId(e.target.value)}
								disabled={isGenerating || isLoadingMap}
								required
							/>
						</div>
						<div className="col-12 mb-3">
							<label className="form-label">Keyword(s):</label>
							<input
								type="text"
								name="keyword"
								className="form-control"
								value={keyword || ''}
								onChange={(e) => setKeyword(e.target.value)}
								disabled={isGenerating || isLoadingMap}
							/>
						</div>
						<div className="col-12 mb-3">
							<label className="form-label" htmlFor="competitor1PlaceId">
								Competitor 1 Place Id:
							</label>
							<input
								type="text"
								name="competitor1PlaceId"
								className="form-control"
								value={competitor1PlaceId || ''}
								onChange={(e) => setCompetitor1PlaceId(e.target.value)}
								disabled={isGenerating || isLoadingMap}
							/>
						</div>
						<div className="col-12 mb-3">
							<label className="form-label" htmlFor="competitor2PlaceId">
								Competitor 2 Place Id:
							</label>
							<input
								type="text"
								name="competitor2PlaceId"
								className="form-control"
								value={competitor2PlaceId || ''}
								onChange={(e) => setCompetitor2PlaceId(e.target.value)}
								disabled={isGenerating || isLoadingMap}
							/>
						</div>
						<div className="col-12 mb-3">
							<label className="form-label" htmlFor="competitor3PlaceId">
								Competitor 3 Place Id:
							</label>
							<input
								type="text"
								name="competitor3PlaceId"
								className="form-control"
								value={competitor3PlaceId || ''}
								onChange={(e) => setCompetitor3PlaceId(e.target.value)}
								disabled={isGenerating || isLoadingMap}
							/>
						</div>
						<div className="col-12">
							<button
								className="btn btn-primary"
								onClick={(e) => generateReport(e)}
								disabled={isGenerating || isLoadingMap}
							>
								{isGenerating ? (
									<Fragment>
										<span className="spinner-border m-0 me-2" />
										<span>Generating Report...</span>
									</Fragment>
								) : (
									<span>Generate Report</span>
								)}
							</button>
						</div>
					</form>
				</div>
			</div>

			<div className="main-content-inner-container grid-tool-content-container p-2">
				<div className="map-wrapper h-100 border position-relative" ref={gridMapContainerRef}>
					{(isGenerating || isLoadingMap) && (
						<div className="loading-map">
							<Spinner />
						</div>
					)}

					<ul className="nav nav-tabs" id="tabReport" role="tablist">
						<li className="nav-item" role="presentation" key="audit-report">
							<button
								className="nav-link active"
								id="audit-report-tab"
								data-bs-toggle="tab"
								data-bs-target="#audit-report-tab-pane"
								type="button"
								role="tab"
								aria-controls="audit-report-tab-pane"
								aria-selected="true"
							>
								Audit
							</button>
						</li>
						<li className="nav-item" role="presentation" key="grid-report">
							<button
								className="nav-link"
								id="grid-report-tab"
								data-bs-toggle="tab"
								data-bs-target="#grid-report-tab-pane"
								type="button"
								role="tab"
								aria-controls="grid-report-tab-pane"
								aria-selected="true"
							>
								Grid
							</button>
						</li>
					</ul>
					<div className="tab-content" id="tabReportContent">
						<div
							className="tab-pane fade show active"
							id="audit-report-tab-pane"
							role="tabpanel"
							aria-labelledby="audit-report-tab"
							tabIndex="0"
						>
							<div className="audit-data-container p-3">
								<table className="items-list audit-table table border table-responsive">
									<thead>
										<tr>
											<th scope="col" className="description">
												Average Gird Rank:
											</th>
											<th className="business" scope="col">
												-
											</th>
											<th className="business competitor" scope="col">
												-
											</th>
											<th className="business competitor" scope="col">
												-
											</th>
											<th className="business competitor" scope="col">
												-
											</th>
										</tr>
										<tr>
											<th scope="col" className="description">
												Description:
											</th>
											<th className="business" scope="col">
												Your Business
											</th>
											<th className="business competitor" scope="col">
												Competitor 1
											</th>
											<th className="business competitor" scope="col">
												Competitor 2
											</th>
											<th className="business competitor" scope="col">
												Competitor 3
											</th>
										</tr>
									</thead>
									{reportData.businessData && reportData.businessData.profile ? (
										<tbody>
											{/* Profile Settings */}
											{settings.map((setting) => (
												<tr key={setting.label}>
													<td className="description">{setting.label}</td>
													{categories.map((cat) => {
														let profile = reportData[cat + 'Data']
															? reportData[cat + 'Data'][setting.type]
															: null;
														let propValue = profile ? profile[setting.propValue] : null;
														let printPropValue =
															profile && setting.printPropValue
																? propValue[setting.printPropValue]
																: propValue;
														let propSubValue =
															profile && propValue
																? propValue[setting.propSubValue]
																: null;
														let isArray = propSubValue
															? Array.isArray(propSubValue)
															: printPropValue && Array.isArray(printPropValue);
														let isObject = propSubValue
															? typeof propSubValue === 'object' && !isArray
															: printPropValue &&
																typeof printPropValue === 'object' &&
																!isArray;
														let showNumber = setting.responseType === 'int';

														return (
															<td
																key={cat}
																className={`business ${cat === 'business'
																	? ''
																	: 'competitor'}`}
															>
																{profile && printPropValue ? (
																	<Fragment>
																		{isArray ? (
																			<Fragment>
																				{printPropValue.length > 0 ? (
																					<button
																						className="btn btn-link p-0 btn-json-output"
																						onClick={() =>
																							printJsonNodeValue(
																								cat,
																								setting
																							)}
																					>
																						<span>
																							{showNumber ? (
																								printPropValue.length
																							) : (
																								'Yes'
																							)}
																						</span>
																					</button>
																				) : (
																					<Fragment>
																						{propSubValue.length > 0 ? (
																							<button
																								className="btn btn-link p-0 btn-json-output"
																								onClick={() =>
																									printJsonNodeValue(
																										cat,
																										setting
																									)}
																							>
																								<span>
																									{showNumber ? (
																										propSubValue.length
																									) : (
																										'Yes'
																									)}
																								</span>
																							</button>
																						) : (
																							<span className="json-output-no-value">
																								No
																							</span>
																						)}
																					</Fragment>
																				)}
																			</Fragment>
																		) : (
																			<Fragment>
																				{isObject ? (
																					<Fragment>
																						{propSubValue ? (
																							<Fragment>
																								{
																									<button
																										className="btn btn-link p-0 btn-json-output"
																										onClick={() =>
																											printJsonNodeValue(
																												cat,
																												setting
																											)}
																									>
																										<span>
																											{showNumber ? (
																												propSubValue
																											) : (
																												'Yes'
																											)}
																										</span>
																									</button>
																								}
																							</Fragment>
																						) : (
																							<span className="json-output-no-value">
																								No
																							</span>
																						)}
																					</Fragment>
																				) : (
																					<button
																						className="btn btn-link p-0 btn-json-output"
																						onClick={() =>
																							printJsonNodeValue(
																								cat,
																								setting
																							)}
																					>
																						<span>
																							{showNumber ? (
																								printPropValue
																							) : (
																								'Yes'
																							)}
																						</span>
																					</button>
																				)}
																			</Fragment>
																		)}
																	</Fragment>
																) : (
																	<span className="json-output-no-value">No</span>
																)}
															</td>
														);
													})}
												</tr>
											))}

											<tr>
												<td className="border border-secondary border-2 p-0" colSpan={5} />
											</tr>
											<tr>
												<td colSpan={5} className="separator-row">
													<strong>Keywords In:</strong>
												</td>
											</tr>

											{/* Keywords In Settings */}
											{kwSettings.map((setting) => (
												<tr key={setting.label}>
													<td className="description">{setting.label}</td>
													{categories.map((cat) => {
														let profile = reportData[cat + 'Data']
															? reportData[cat + 'Data'][setting.type]
															: null;
														let propValue = profile ? profile[setting.propValue] : null;
														let printPropValue =
															profile && setting.printPropValue
																? propValue[setting.printPropValue]
																: propValue;
														let propSubValue =
															profile && propValue
																? propValue[setting.propSubValue]
																: null;
														let isArray = propSubValue
															? Array.isArray(propSubValue)
															: printPropValue && Array.isArray(printPropValue);
														let isObject = propSubValue
															? typeof propSubValue === 'object' && !isArray
															: printPropValue &&
																typeof printPropValue === 'object' &&
																!isArray;
														let showNumber = setting.responseType === 'int';

														return (
															<td
																key={cat}
																className={`business ${cat === 'business'
																	? ''
																	: 'competitor'}`}
															>
																{profile && printPropValue ? (
																	<Fragment>
																		{isArray ? (
																			<Fragment>
																				{printPropValue.length > 0 ? (
																					<button
																						className="btn btn-link p-0 btn-json-output"
																						onClick={() =>
																							printJsonNodeValue(
																								cat,
																								setting
																							)}
																					>
																						<span>
																							{showNumber ? (
																								printPropValue.length
																							) : (
																								'Yes'
																							)}
																						</span>
																					</button>
																				) : (
																					<Fragment>
																						{propSubValue.length > 0 ? (
																							<button
																								className="btn btn-link p-0 btn-json-output"
																								onClick={() =>
																									printJsonNodeValue(
																										cat,
																										setting
																									)}
																							>
																								<span>
																									{showNumber ? (
																										propSubValue.length
																									) : (
																										'Yes'
																									)}
																								</span>
																							</button>
																						) : (
																							<span className="json-output-no-value">
																								No
																							</span>
																						)}
																					</Fragment>
																				)}
																			</Fragment>
																		) : (
																			<Fragment>
																				{isObject ? (
																					<Fragment>
																						{propSubValue ? (
																							<Fragment>
																								{
																									<button
																										className="btn btn-link p-0 btn-json-output"
																										onClick={() =>
																											printJsonNodeValue(
																												cat,
																												setting
																											)}
																									>
																										<span>
																											{showNumber ? (
																												propSubValue
																											) : (
																												'Yes'
																											)}
																										</span>
																									</button>
																								}
																							</Fragment>
																						) : (
																							<span className="json-output-no-value">
																								No
																							</span>
																						)}
																					</Fragment>
																				) : (
																					<button
																						className="btn btn-link p-0 btn-json-output"
																						onClick={() =>
																							printJsonNodeValue(
																								cat,
																								setting
																							)}
																					>
																						<span>
																							{showNumber ? (
																								printPropValue
																							) : (
																								'Yes'
																							)}
																						</span>
																					</button>
																				)}
																			</Fragment>
																		)}
																	</Fragment>
																) : (
																	<span className="json-output-no-value">No</span>
																)}
															</td>
														);
													})}
												</tr>
											))}

											{/* Power Content */}
											{reportData['businessData'] &&
											reportData['businessData'].powerContent &&
											reportData['businessData'].powerContent.length > 0 && (
												<Fragment>
													<tr>
														<td colSpan={5} className="separator-row">
															Power Content
														</td>
													</tr>
													{reportData['businessData'].powerContent.map((pc, pcIndex) => {
														return (
															<tr key={pcIndex}>
																<td className="description">{pc.website}</td>
																{categories.map((cat) => {
																	let pcItem =
																		reportData[cat + 'Data'] &&
																		reportData[cat + 'Data'].powerContent &&
																		reportData[cat + 'Data'].powerContent.length > 0
																			? reportData[
																					cat + 'Data'
																				].powerContent.find(
																					(x) => x.website === pc.website
																				)
																			: null;

																	return (
																		<td
																			key={cat}
																			className={`business ${cat === 'business'
																				? ''
																				: 'competitor'}`}
																		>
																			{pcItem && pcItem.total ? (
																				<Fragment>
																					<button className="btn btn-link p-0 btn-json-output">
																						<span>{pcItem.total}</span>
																					</button>
																				</Fragment>
																			) : (
																				'No'
																			)}
																		</td>
																	);
																})}
															</tr>
														);
													})}
												</Fragment>
											)}

											<tr>
												<td className="border border-secondary border-2 p-0" colSpan={5} />
											</tr>

											{/* Domain Age */}
											<tr>
												<td className="description">Domain Age</td>
												{categories.map((cat) => {
													let dateNow = moment(new Date()); //'DD/MM/YYYY HH:mm:ss'
													let elapsedYears =
														reportData[cat + 'Data'] &&
														reportData[cat + 'Data'].whois &&
														reportData[cat + 'Data'].whois.CreatedDate
															? dateNow.diff(
																	reportData[cat + 'Data'].whois.CreatedDate,
																	'years'
																)
															: '';

													return (
														<td key={cat} className="business">
															{elapsedYears ? (
																`${elapsedYears} ${elapsedYears === 1
																	? 'Year'
																	: 'Years'}`
															) : (
																''
															)}
														</td>
													);
												})}
											</tr>

											{/* Total Indexed Pages */}
											<tr>
												<td className="description">Total Indexed Pages</td>
												{categories.map((cat) => (
													<td key={cat} className="business">
														{reportData[cat + 'Data'] &&
														reportData[cat + 'Data'].indexedPages &&
														reportData[cat + 'Data'].indexedPages.total ? (
															reportData[cat + 'Data'].indexedPages.total
														) : (
															''
														)}
													</td>
												))}
											</tr>

											{/* Backlinks */}
											<tr>
												<td className="description">Backlinks</td>
												<td className="business"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
												<td className="business competitor"> </td>
											</tr>

											{/* Total Citations */}
											<tr>
												<td className="description">Total Citations</td>
												{categories.map((cat) => (
													<td
														key={cat}
														className={`business ${cat === 'business' ? '' : 'competitor'}`}
													>
														{reportData[cat + 'Data'] &&
														reportData[cat + 'Data'].citations &&
														reportData[cat + 'Data'].citations.total ? (
															<Fragment>
																<button className="btn btn-link p-0 btn-json-output">
																	<span>
																		{reportData[cat + 'Data'].citations.total}
																	</span>
																</button>
															</Fragment>
														) : (
															''
														)}
													</td>
												))}
											</tr>

											{/* Power Citations */}
											{reportData['businessData'] &&
											reportData['businessData'].powerCitations &&
											reportData['businessData'].powerCitations.length > 0 && (
												<Fragment>
													<tr>
														<td colSpan={5} className="separator-row">
															Power Citations
														</td>
													</tr>
													{reportData['businessData'].powerCitations.map((pc, pcIndex) => {
														return (
															<tr key={pcIndex}>
																<td className="description">{pc.website}</td>
																{categories.map((cat) => {
																	let pcItem =
																		reportData[cat + 'Data'] &&
																		reportData[cat + 'Data'].powerCitations &&
																		reportData[cat + 'Data'].powerCitations.length >
																			0
																			? reportData[
																					cat + 'Data'
																				].powerCitations.find(
																					(x) => x.website === pc.website
																				)
																			: null;

																	return (
																		<td
																			key={cat}
																			className={`business ${cat === 'business'
																				? ''
																				: 'competitor'}`}
																		>
																			{pcItem && pcItem.total ? (
																				<Fragment>
																					<button className="btn btn-link p-0 btn-json-output">
																						<span>{pcItem.total}</span>
																					</button>
																				</Fragment>
																			) : (
																				'No'
																			)}
																		</td>
																	);
																})}
															</tr>
														);
													})}
												</Fragment>
											)}

											<tr>
												<td className="border border-secondary border-2 p-0" colSpan={5} />
											</tr>

											{/* Review Score */}
											<tr>
												<td className="separator-row">Review Score</td>
												{categories.map((cat) => <td key={cat} className="separator-row" />)}
											</tr>

											{/* Total Reviews */}
											<tr>
												<td className="description">Total Reviews</td>
												{categories.map((cat) => (
													<td key={cat} className="business">
														{reportData[cat + 'Data'] &&
														reportData[cat + 'Data'].reviews &&
														reportData[cat + 'Data'].reviews.total ? (
															reportData[cat + 'Data'].reviews.total
														) : (
															0
														)}
													</td>
												))}
											</tr>

											{/* Average Rating */}
											<tr>
												<td className="description">Average Rating</td>
												{categories.map((cat) => (
													<td key={cat} className="business">
														{reportData[cat + 'Data'] &&
														reportData[cat + 'Data'].profile &&
														reportData[cat + 'Data'].profile.Rating &&
														reportData[cat + 'Data'].profile.Rating.Value ? (
															reportData[cat + 'Data'].profile.Rating.Value
														) : (
															0
														)}
													</td>
												))}
											</tr>

											{/* Reviews Last 30 Days */}
											<tr>
												<td colSpan={5} className="separator-row">
													<strong>Last 30 Days</strong>
												</td>
											</tr>
											{reviewsLast30DaysSettings.map((setting) => (
												<tr key={setting.label}>
													<td className="description">{setting.label}</td>
													{categories.map((cat) => {
														let propValue =
															reportData[cat + 'Data'] &&
															reportData[cat + 'Data']['reviews'] &&
															reportData[cat + 'Data']['reviews']['last30Days'] &&
															reportData[cat + 'Data']['reviews']['last30Days'][
																setting.propValue
															]
																? reportData[cat + 'Data']['reviews']['last30Days'][
																		setting.propValue
																	]
																: null;
														let printPropValue = propValue;
														let isArray = printPropValue && Array.isArray(printPropValue);
														let isObject =
															printPropValue &&
															typeof printPropValue === 'object' &&
															!isArray;
														let showNumber = setting.responseType === 'int';

														if (
															typeof printPropValue == 'number' &&
															!isNaN(printPropValue) &&
															!Number.isInteger(printPropValue)
														) {
															printPropValue = parseFloat(printPropValue).toFixed(2);
														}

														return (
															<td
																key={cat}
																className={`business ${cat === 'business'
																	? ''
																	: 'competitor'}`}
															>
																{printPropValue ? (
																	<Fragment>
																		{isArray ? (
																			<Fragment>
																				{printPropValue.length > 0 ? (
																					<button
																						className="btn btn-link p-0 btn-json-output"
																						onClick={() =>
																							printJsonNodeValue(
																								cat,
																								setting
																							)}
																					>
																						<span>
																							{showNumber ? (
																								printPropValue.length
																							) : (
																								'Yes'
																							)}
																						</span>
																					</button>
																				) : (
																					<Fragment />
																				)}
																			</Fragment>
																		) : (
																			<Fragment>
																				{isObject ? (
																					<Fragment />
																				) : (
																					<button
																						className="btn btn-link p-0 btn-json-output"
																						onClick={() =>
																							printJsonNodeValue(
																								cat,
																								setting
																							)}
																					>
																						<span>
																							{showNumber ? (
																								printPropValue
																							) : (
																								'Yes'
																							)}
																						</span>
																					</button>
																				)}
																			</Fragment>
																		)}
																	</Fragment>
																) : (
																	<span className="json-output-no-value">
																		{showNumber ? 0 : 'No'}
																	</span>
																)}
															</td>
														);
													})}
												</tr>
											))}

											<tr>
												<td colSpan={5} className="separator-row">
													<strong>Last 20 Reviews</strong>
												</td>
											</tr>
											{reviewsLast20Settings.map((setting) => (
												<tr key={setting.label}>
													<td className="description">{setting.label}</td>
													{categories.map((cat) => {
														let propValue =
															reportData[cat + 'Data'] &&
															reportData[cat + 'Data']['reviews'] &&
															reportData[cat + 'Data']['reviews']['last20'] &&
															reportData[cat + 'Data']['reviews']['last20'][
																setting.propValue
															]
																? reportData[cat + 'Data']['reviews']['last20'][
																		setting.propValue
																	]
																: null;
														let printPropValue = propValue;
														let isArray = printPropValue && Array.isArray(printPropValue);
														let isObject =
															printPropValue &&
															typeof printPropValue === 'object' &&
															!isArray;
														let showNumber = setting.responseType === 'int';

														if (
															typeof printPropValue == 'number' &&
															!isNaN(printPropValue) &&
															!Number.isInteger(printPropValue)
														) {
															printPropValue = parseFloat(printPropValue).toFixed(2);
														}

														return (
															<td
																key={cat}
																className={`business ${cat === 'business'
																	? ''
																	: 'competitor'}`}
															>
																{printPropValue ? (
																	<Fragment>
																		{isArray ? (
																			<Fragment>
																				{printPropValue.length > 0 ? (
																					<button
																						className="btn btn-link p-0 btn-json-output"
																						onClick={() =>
																							printJsonNodeValue(
																								cat,
																								setting
																							)}
																					>
																						<span>
																							{showNumber ? (
																								printPropValue.length
																							) : (
																								'Yes'
																							)}
																						</span>
																					</button>
																				) : (
																					<Fragment />
																				)}
																			</Fragment>
																		) : (
																			<Fragment>
																				{isObject ? (
																					<Fragment />
																				) : (
																					<button
																						className="btn btn-link p-0 btn-json-output"
																						onClick={() =>
																							printJsonNodeValue(
																								cat,
																								setting
																							)}
																					>
																						<span>
																							{showNumber ? (
																								printPropValue
																							) : (
																								'Yes'
																							)}
																						</span>
																					</button>
																				)}
																			</Fragment>
																		)}
																	</Fragment>
																) : (
																	<span className="json-output-no-value">
																		{showNumber ? 0 : 'No'}
																	</span>
																)}
															</td>
														);
													})}
												</tr>
											))}

											{/* Most Relevant Review Age */}
											<tr>
												<td className="description">
													Most Relevant Review Age (Sort By Most Relevant)
												</td>
												{categories.map((cat) => {
													let printSetting = {
														label        : 'Most Relevant Review',
														type         : 'reviews',
														propValue    : 'mostRelevant',
														propSubValue : 'first'
													};
													return (
														<td
															key={cat}
															className={`business ${cat === 'business'
																? ''
																: 'competitor'}`}
														>
															{reportData[cat + 'Data'] &&
															reportData[cat + 'Data'].reviews &&
															reportData[cat + 'Data'].reviews.mostRelevant &&
															reportData[cat + 'Data'].reviews.mostRelevant.first &&
															reportData[cat + 'Data'].reviews.mostRelevant.first.age ? (
																<button
																	className="btn btn-link p-0 btn-json-output"
																	onClick={() =>
																		printJsonNodeValue(cat, printSetting)}
																>
																	<span>
																		{reportData[
																			cat + 'Data'
																		].reviews.mostRelevant.first.age.replace(
																			' ago',
																			''
																		)}
																	</span>
																</button>
															) : (
																''
															)}
														</td>
													);
												})}
											</tr>
										</tbody>
									) : (
										<tbody>
											<tr>
												<td colSpan={5}>
													<i>No Data</i>
												</td>
											</tr>
										</tbody>
									)}
								</table>
							</div>
						</div>
						<div
							className="tab-pane fade"
							id="grid-report-tab-pane"
							role="tabpanel"
							aria-labelledby="grid-report-tab"
							tabIndex="0"
						>
							{gridData && (
								<Fragment>
									<GridMapV5
										key="map-0"
										mapIdValue="report-map-0"
										gridData={gridData}
										placeId={gridData.googlePlaceId}
										isFullHeight={true}
										reportSettings={reportSettings}
									/>
								</Fragment>
							)}
						</div>
					</div>
				</div>
			</div>

			{showJsonOutputDialog && (
				<ModalDialog className="text-start" position="right-sidebar">
					<ModalDialogContent align="start">
						{/* <h4 className="mb-4">
							<span />
						</h4> */}
						<div className="row" dangerouslySetInnerHTML={{ __html: jsonOutput }} />
					</ModalDialogContent>
					<ModalDialogButtons>
						<div className="row">
							<div className="text-start col-12">
								<button className="btn btn-outline-primary" onClick={toggleJsonOutputDialog}>
									<span>Close</span>
								</button>
							</div>
						</div>
					</ModalDialogButtons>
				</ModalDialog>
			)}
		</div>
	);
};

export default GridReportToolNewDemo;
